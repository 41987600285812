import favicon from "@/assets/favicon.png";

import metadata from "@/../.sourceflow/metadata.json";
import Head from "next/head";

const SEO = ({
  path,
  title: _title,
  description: _description,
  keywords: _keywords,
  image,
}) => {
  const cms_data = metadata[path];
  const title = cms_data?.find((i) => i.name === "title");
  const description = cms_data?.find((i) => i.name === "description");
  const keywords = cms_data?.find((i) => i.name === "keywords");
  const canonical = cms_data?.find((i) => i.name === "keywords");

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=0.9" />
      <meta
        name="description"
        content={description?.attributes?.content ?? _description}
      />
      <meta
        name="keywords"
        content={keywords?.attributes?.content ?? _keywords}
      />
      <meta property="twitter:card" content="summary" />
      <meta
        property="og:url"
        content={
          canonical?.attributes?.href ?? `https://www.lawrenceharvey.com${path}`
        }
      />
      <meta property="og:title" content={title?.body ?? _title} />
      <meta
        property="og:description"
        content={description?.attributes?.content ?? _description}
      />
      {image && <meta property="og:image" content={image} />}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="600" />
      {image && <meta property="twitter:image" content={image} />}
      <meta property="twitter:title" content={title?.body ?? _title} />
      <meta
        property="twitter:description"
        content={description?.attributes?.content ?? _description}
      />
      <link rel="icon" type="image/x-icon" href={favicon ?? ""} />
      <link
        rel="canonical"
        href={
          canonical?.attributes?.href ?? `https://www.lawrenceharvey.com${path}`
        }
      />
      <title>{title?.body ?? _title}</title>
      <meta name="apple-mobile-web-app-capable" content="yes" />
       {/* <!-- Google tag (gtag.js) --> */}
       <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-8S0BEBLCFK"
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
						  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-8S0BEBLCFK');
						`,
          }}
        />
    </Head>
  );
};

SEO.defaultProps = {
  image: "https://www.lawrenceharvey.com/og-image.jpg",
};

export default SEO;
